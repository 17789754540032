<template>
  <div class="bgcard">
    <div class="headerMsg">
      <div class="addbtn">
        <button @click="adddevice">{{ $t("lang.Softwareupgrade") }}</button>
      </div>
      <div class="searchlist">
        <!-- <div class="query">
          <div class="inputname">
            <input v-model="input" :placeholder="$t('lang.Inputfilename')" />
          </div>
          <div class="searchbtn">
            <button @click="searth">
              <p>{{ $t("lang.search") }}</p>
            </button>
          </div>
        </div> -->
      </div>
    </div>
    <div class="serverfroms">
      <el-table :data="tableData.package" style="width: 100%">
        <template slot="empty">
          <div class="noCart">{{ $t("lang.tableNull") }}</div>
        </template>
        <el-table-column :label="$t('lang.fileName')" align="center">
					<template #default="scope">
					  <span v-if="scope.row.devType == 1">UV100</span>
					  <span v-if="scope.row.devType == 2">UV800</span>
					</template>
        </el-table-column>
        <el-table-column prop="download_url" :label="$t('lang.devdownload')" align="center">
        </el-table-column>
        <el-table-column
          prop="version"
          :label="$t('lang.Generationtime')"
          align="center"
        >
        </el-table-column>
        <el-table-column :label="$t('lang.Modelofequipmentused')" align="center">
					<template #default="scope">
					  <span>{{scope.row.version_tm |time}}</span>
					</template>
        </el-table-column>
        <el-table-column prop="version_min_tm" :label="$t('lang.versions')" align="center">
        </el-table-column>
		<!-- <el-table-column
		  :label="$t('lang.operation')"
		  width="250"
		  align="center"
		>
		  <template #default="scope">
		    <div class="bottom_tabes">
		      <el-tooltip
		        effect="dark"
		        :content="seenFivetext"
		        placement="left"
		      >
		        <div
		          class="bottom_imgs bottom_delete"
		          @mouseenter="enterOne(4, scope.row.id, scope.row.flag4)"
		          @mouseleave="leaveOne(4, scope.row.id, scope.row.flag4)"
		          @click="deletemsg(scope.row)"
		        >
		          <img
		            class="imgOne"
		            v-if="scope.row.flag4 === 0"
		            src="@/assets/imgs/bottom/shanchu.png"
		            alt=""
		          />
		          <img
		            class="imgTwo"
		            v-if="scope.row.flag4 === 1"
		            src="@/assets/imgs/bottom/shanchu2.png"
		            alt=""
		          />
		        </div>
		      </el-tooltip>
		    </div>
		  </template>
		</el-table-column> -->
      </el-table>
      <!-- <div style="display: flex; align-items: center"  v-if="tableData.content">
        <div class="allNum">{{ $t("lang.total") }}: {{ tableData.totalElements }}</div>
        <el-pagination
          popper-class="paging"
          background
         
          layout="prev, pager, next"
          :total="tableData.totalElements"
          @current-change="handlePageCurrentChange"
          :prev-text="$t('lang.previouspage')"
          :next-text="$t('lang.nextpage')"
        >
        </el-pagination>
      </div> -->
    </div>
    <el-dialog
      :title="$t('lang.Softwareupgrade')"
      :visible.sync="showSees"
      :show-close="false"
      width="4rem"
      top="2.5rem"
      custom-class="seteqcs_a"
    >
      <!-- <div class="binding">
        <label>{{ $t("lang.Selectmodel") }}：</label>
        <el-select v-model="file.deviceModel" :placeholder="$t('lang.Please')">
          <el-option
            v-for="item in models"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </div>
      <div class="binding">
        <label>{{ $t("lang.Latestversionnumber") }}：</label>
        <el-input v-model="file.softVer"></el-input>
      </div> -->
      <div class="upgrade">
        <label>{{ $t("lang.Equipmentupgrade") }}：</label>
        <div>
          <el-upload
            class="upload-demo"
            :action="url"
            :before-remove="beforeRemove"
            :limit="1"
            :headers="headers"
            :on-exceed="
              () => {
                $message.error($t('lang.Themaximumnumber'));
              }
            "
            :file-list="fileList"
            :on-success="success"
            :before-upload="beforeUpload"
          >
            <el-button size="small" type="primary">+{{ $t("lang.Selectfile") }}</el-button>
          </el-upload>
          <!-- <el-checkbox v-model="checked">{{
            $t("lang.Forcedupgradeafterupload")
          }}</el-checkbox> -->
        </div>
      </div>
      <div>
        <span class="dialog-footer">
          <button class="button_a" @click="showSees = false">
            <p>{{ $t("lang.cancel") }}</p>
          </button>
          <button class="button_a" @click="determine">
            <p>{{ $t("lang.determine") }}</p>
          </button>
        </span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import * as pment from "@/api/equipment.js";
import * as baseURL from "@/utils/request.js";
import { getTime } from "@/utils/time";
export default {
  data() {
    return {
      input: "",
      tableData: [],
      parsm: {
        page: 1,
        size: 10,
      },
      showSees: false,
      models: [],
      fileList: [],
      checked: false,
      file: {
        //上传文件信息
        deviceModel: "",
        softVer: "",
        url: "",
      },
      url: "", //文件上传路径
      tokens: "",
	  seenFivetext: this.$t("lang.deletes"),
    };
  },
	filters:{
		time(val){
			return getTime(val*1000).time
		}
	},
  mounted() {
    this.url = baseURL.uploads;
    let accessToken = sessionStorage.getItem("token");
    this.tokens = JSON.parse(accessToken);
    this.getList();
  },
  computed: {
    headers() {
      return {
        Authorization: this.tokens, // 直接从本地获取token就行
      };
    },
  },
  methods: {
    //获取升级文件列表
    async getList() {
      let res = await pment.filelists();
      console.log(res);
			this.tableData = res.data;
    //   if (res.data.content) {
		  // res.data.content.forEach((item) => {
		  //   item.flag4 = 0;
		  // });
    //     this.tableData = res.data;
    //   }
    },
    //获取设备型号
    async GetModel() {
      let res = await pment.model();
      if (res.data) {
        this.models = res.data;
      }
    },
    //打开软件升级模态框
    adddevice() {
      this.showSees = true;
      this.fileList = [];
      this.file = {
        deviceModel: "",
        softVer: "",
        url: "",
      };
      this.checked = false;
      this.GetModel();
    },
    //上传成功的确认
    determine() {
			this.showSees = false
			this.getList();
      // if (this.checked == false) {
      //   this.file.state = 0;
      // } else {
      //   this.file.state = 1;
      // }
      // if (this.file.deviceModel == "") {
      //   this.$message.error(this.$t("lang.Pleaseselectthedevicemodel"));
      //   return false;
      // }
      // if (this.file.softVer == "") {
      //   this.$message.error(this.$t("lang.Pleaseenterthelatestversionnumber"));
      //   return false;
      // }
      // if (this.file.url == "") {
      //   this.$message.error(this.$t("lang.Pleaseselectupgradefile"));
      //   return false;
      // }
      // let adminemail = sessionStorage.getItem("user");
      // if (adminemail) {
      //   this.file.adminEmail = JSON.parse(adminemail).email;
      // }
      // pment.addfile(this.file).then((res) => {
      //   console.log(res);
      //   this.$message.success(res.msg);
      //   this.showSees = false;
      //   this.getList();
      // });
    },
    /** 分页页数发生改变 */
    handlePageCurrentChange(page) {
      this.parsm.page = page;
      this.getList();
    },
    //输入文件名称筛选
    searth() {
      this.parsm.page = 1;
      this.parsm.size = 10;
      this.parsm.name = this.input;
      this.getList();
    },
    //上传成功之后
    success(res) {
			let dase=JSON.parse(res.data)
      if (dase.result===0) {
        this.$message.success(this.$t("lang.Uploadsucceeded"));
        this.file.url = res.data.path;
        this.file.name = res.data.name;
        this.file.size = res.data.size;
      }else{
				this.fileList=[]
				if(dase.result===-1){
					this.$message.error(this.$t("lang.Noversion"));
				}else if(dase.result===-2){
					this.$message.error(this.$t("lang.Upgradepackage"));
				}else if(dase.result===-3){
					this.$message.error(this.$t("lang.Thereisan"));
				}else if(dase.result===-4){
					this.$message.error(this.$t("lang.Theupgrade"));
				}else if(dase.result===-5){
					this.$message.error(this.$t("lang.Upgrademodule"));
				}else if(dase.result===-6){
					this.$message.error(this.$t("lang.Thereareupgrade"));
				}else if(dase.result===-7){
					this.$message.error(this.$t("lang.Illegalrequest"));
				}else if(dase.result===-8){
					this.$message.error(this.$t("lang.Failedtoupload"));
				}else if(dase.result===-9){
					this.$message.error(this.$t("lang.Theupgrades"));
				}
			}
    },
    //删除文件之前
    beforeRemove(file) {
      return this.$confirm(
        this.$t("lang.Areyousureyouwanttoremove") + `${file.name}？`
      );
    },
    //上传文件之前
    beforeUpload(file) {
			const filename=file.name
			const filetype=filename.lastIndexOf('.tar.gz')
			if(filetype==-1){
				this.$message.error(this.$t("lang.package"));
				return false
			}
   //    const isLt10M = file.size / 1024 / 1024 < 10;
   //    if (!isLt10M){
			// 	this.$message.error(this.$t("lang.Thesizeoftheuploadedfile") + "10MB!");
			// 	return false
			// } 
    },
	//删除
	deletemsg(row) {
	  this.$confirm(
	    this.$t("lang.Thisoperationdeletetheadministrators"),
	    this.$t("lang.Tips"),
	    {
	      confirmButtonText: this.$t("lang.determine"),
	      cancelButtonText: this.$t("lang.cancel"),
	      type: "warning",
	    }
	  ).then(() => {
		  let dase={
			  id:row.id
		  }
	    pment.deletefile(dase).then((res) => {
	      this.$message.success(this.$t("lang.deletessuccess"));
		  this.isLastPage();
	      this.getList();
	    });
	  });
	},
	//判断当前页是否是最后一页
	isLastPage() {
	  let lastPage = Math.ceil((this.tableData.totalElements - 1) / this.parsm.size);
	  //console.log(lastPage)
	  if (this.parsm.page == lastPage || this.parsm.page > lastPage) {
	    this.parsm.page = lastPage;
	  }
	},
  },
};
</script>

<style lang="less" scoped>
.bgcard {
  border: 0px;
  box-sizing: border-box;
  padding: 0.3rem;
  border-radius: 0.02rem;
  background: #222628;
}
.headerMsg {
  display: flex;
  justify-content: space-between;

  .addbtn {
    button {
      height: 0.5rem;
      border: none;
      outline-style: none;
      padding: 0px 0.12rem;
      background: #307b37;
      border-radius: 0.1rem;
      font-size: 0.18rem;
      font-family: PingFang SC;
      font-weight: 500;
      color: #ffffff;
    }
  }
}
.upgrade {
  width: 3.5rem;
  margin-bottom: 0.2rem;
  display: flex;

  label {
    width: 1rem;
    height: 0.4rem;
    text-align: right;
    vertical-align: middle;
    float: left;
    font-size: 0.14rem;
    color: #606266;
    line-height: 0.4rem;
    padding: 0 0.12rem 0 0;
    box-sizing: border-box;
  }

  div {
    width: 2rem;

    .el-button {
      margin-top: 0.05rem;
      background-color: #1061c5;
      color: #fff;
      border: none;
    }
  }
}
.buttons {
  padding: 0px;
  width: 0.82rem;
  height: 0.42rem;
  outline-style: none;
  border: none;
  border-radius: 0.04rem;
  font-size: 0.16rem;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
  background-color: #24b4de;
}
.binding {
  margin-bottom: 0.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  label {
    width: 1rem;
  }
  .el-input,
  .el-select {
    width: 2rem;
  }
}
.button_a {
  border: none;
  outline-style: none;
  width: 0.92rem;
  height: 0.46rem;
  background: #ededed;
  border: 0.01rem solid #438749;
  border-radius: 0.1rem;

  p {
    font-size: 0.18rem;
    font-family: PingFang SC;
    font-weight: 500;
    color: #438749;
    line-height: 0.35rem;
    text-align: center;
  }
}

.button_a:nth-child(2) {
  width: 0.92rem;
  height: 0.46rem;
  background: #438749;
  border-radius: 0.1rem;
  margin-left: 0.2rem;

  p {
    font-size: 0.18rem;
    font-family: PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 0.35rem;
  }
}
</style>
